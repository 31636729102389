/* App container */
.App {
  text-align: center;
  background-color: #c1d8f0; /* Light beige for better contrast */
  border-radius: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto; /* Center horizontally */
  margin-top: 20px;
  padding: 30px;
}

/* Center the form elements */
form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; /* Ensure form wraps on smaller screens */
}


form label {
  width: 49%
}

input[type="text"],
input[type="date"] {
  padding: 10px;
  font-size: 1rem;
  border: 2px solid #ff6347; /* Tomato color for stronger contrast */
  border-radius: 10px;
  margin: 10px; /* Add some margin for spacing */
  width: 80%; /* Full width for smaller screens */
  text-align: center;
  background-color: #fff0f0; /* Very light pink background */
  color: #333; /* Darker text color for readability */
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #ff6347; /* Tomato color for contrast */
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%; /* Full width for smaller screens */
  max-width: 200px; /* Limit width on larger screens */
}

button:hover {
  background-color: #ff4500; /* Darker red for hover */
}

/* Ensure consistent styling for buttons */
.share-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap; /* Allow buttons to wrap on smaller screens */
  flex-direction: column;
}

.share-buttons button,
.share-buttons a { /* Target both buttons and the links created by react-share */
  background-color: #ff6347; /* Same background color */
  color: white;
  border-radius: 10px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  text-align: center;
  flex-grow: 1; /* Makes buttons grow evenly */
  max-width: 200px; /* Optional max width */
  width: 100%; /* Ensure all buttons have the same width */
  text-decoration: none; /* Remove underline from <a> elements */
  display: inline-block; /* Ensure <a> behaves like a button */
}

.share-buttons button {
  margin-top: 0;
}

.share-buttons button:hover,
.share-buttons a:hover {
  background-color: #ff4500; /* Darker color on hover */
}

/* Fun image generation box */
.dog-themed-image {
  width: 100%; /* Make it responsive */
  max-width: 500px; /* Limit the width for larger screens */
  height: auto; /* Maintain aspect ratio */
  background-color: #fff0f0; /* Light pink with higher contrast */
  border-radius: 15px;
  padding: 20px;
  margin-top: 20px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.dog-themed-content {
  text-align: center;
}

.dog-themed-content h1 {
  font-size: 2.2rem;
  color: #ff4500; /* Darker red-orange for strong contrast */
}

.dog-themed-content h2 {
  font-size: 1.5rem;
  color: #ff6347; /* Tomato color */
}

.dog-image {
  width: 100%; /* Full width for smaller screens */
  max-width: 350px; /* Limit size for larger screens */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover;
  margin-top: 20px;
  border-radius: 50%;
}

/* Background for inputs */
.name-inputs {
  display: flex;
  flex-direction: row; /* Make name inputs side by side */
  justify-content: center;
  gap: 10px; /* Add gap between name inputs */
  margin: 20px 0;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

/* Ensure the container for the Lottie animation doesn't cut off the animation */
.animation-container {
  width: 100%; /* Make sure the container is responsive */
  max-width: 400px; /* Limit the width for larger screens */
  height: auto; /* Ensure the height adjusts automatically */
  margin: 0 auto; /* Center the animation horizontally */
  display: flex; /* Center the content inside */
  align-items: center; /* Vertically center the animation */
  justify-content: center; /* Horizontally center the animation */
  overflow: hidden; /* Make sure no content is hidden */
}

/* Responsive media queries for smaller screens */
@media (max-width: 768px) {
  .App {
    padding: 20px; /* Reduce padding on smaller screens */
    margin: 20px;
  }

  .dog-themed-content h1 {
    font-size: 1.8rem; /* Reduce font size for smaller screens */
  }

  .dog-themed-content h2 {
    font-size: 1rem; /* Reduce font size for smaller screens */
  }

  .share-buttons {
    flex-direction: column; /* Stack buttons vertically on smaller screens */
  }

  .share-buttons button,
  .share-buttons a {
    width: 100%; /* Full width for buttons on smaller screens */
    max-width: none; /* Remove max-width constraint */
  }

  .name-inputs {
    flex-direction: column; /* Stack inputs vertically on smaller screens */
  }

  form {
    flex-direction: column; /* Stack input fields vertically */
  }

  form label {
    width: 100%
  }

  .dog-themed-image {
    padding: 0;
    width: 100%;
    padding-top: 100%;
    position: relative;
    overflow: hidden;
  }

  .dog-themed-content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .dog-image {
    margin-top: 0;
    max-width: 60%; /* Full width for images on smaller screens */
  }
}
